.side-drawer {
	height: 100%;
	background-color: white;
	position: fixed;
	top: 0;
	left: 0;
	width: 85%;
	max-width: 400px;
	z-index: 200;
	transform: translateX(-100%);
	transition: transform 0.3s ease-out;
}

.side-drawer.open {
	box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
	transform: translateX(0);
}

.SideDrawerList {
	list-style: none;
	padding-inline-start: 1em;
}

.side-drawer li {
	margin: 0.5rem 0;
	transition: all 0.3 ease;
}

.side-drawer a {
	color: black;
	text-decoration: none;
	font-size: 1.2rem;
}

.side-drawer a:hover,
.side-drawer:active {
	color: #e11f2e;
}

@media (min-width: 1015px) {
	.side-drawer {
		display: none;
	}
}

.SideDrawerInfoList {
	list-style: none;
	display: flex;
	flex-direction: column;
	left: 0px;
	position: absolute;
	padding-inline-start: 1em;
}

.SideDrawerInfo a p {
	display: inline;
	font-size: 18px;
}

.sideDrawerIconImg {
	width: 15px;
	margin-right: 0.2em;
}

.SideDrawerIcon {
	top: 20px;
	position: absolute;
	padding: 0px;
}

.sideDrawerLogo {
	width: 10em;
}

.SideDrawerTop {
	display: flex;
	align-items: center;
	margin-bottom: 5vh;
	column-gap: 4em;
}

.SideDrawerCenter {
	margin-bottom: 5vh;
}

.SideDrawerCenter li {
	margin-bottom: 1em;
}

.SideDrawerTop div {
	cursor: pointer;
}

.sideDrawerCloser {
	width: 1.5em;
}

.activeTab {
	color: #e11f2e !important;
}
