.allNews {
	display: flex;
	text-decoration: none;
	justify-content: center;
}

.frontNewsItem {
	margin: 0em 0.7em;
	align-items: center;
	display: flex;
}

.newsLink {
	text-decoration: none;
	color: black;
	max-width: 25em;
}

.newsLink:hover > h3 {
	color: red;
	transition: all 0.4s ease-in-out;
	cursor: pointer !important;
}

.frontNewsTitle {
	display: flex;
	margin: 0.5em 0.85em;
}

.frontNewsDate {
	margin: 0em;
	margin-left: 1.25em;
	font-size: 0.8em;
}

.frontNewsImageContainer {
	display: flex;
	justify-content: center;
}

.frontNewsImage {
	height: 15em;
}

.frontNewsDescription {
	margin: 0em 1em;
	height: 11em;
	overflow-y: scroll;
	text-align: justify;
}

@media (max-width: 750px) {
	.allNews {
		display: block;
		text-decoration: none;
		justify-content: center;
	}
	.news {
		display: flex;
		justify-content: center;
	}

	.frontNewsDescription {
		height: 7em;
	}
}
