.toolbar {
	z-index: 10;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 80px;
	background-color: white;
	box-shadow: 0px 0.1px 4px gray;
}

.toolbar_navigation {
	display: flex;
	height: 100%;
	align-items: center;
	padding: 0 1rem;
}

.toolbar_logo {
	margin-left: 1rem;
}

.toolbar_logo a {
	color: black;
	text-decoration: none;
}

.NavbarLogo {
	height: 4rem;
}

.spacer {
	flex: 1;
}

.toolbar_navigation-items ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
}

.mainNavLink {
	font-size: 20px;
}

@media (max-width: 1119px) {
	.NavbarLogo {
		height: 3rem;
	}
	.mainNavLink {
		font-size: 19px;
	}
}

.toolbar_navigation-items .mainNavLink {
	padding: 0.5rem 2.5rem;
}

@media (max-width: 1400px) {
	.toolbar_navigation-items .mainNavLink {
		padding: 0.5rem 1.5rem;
	}
}

@media (max-width: 1200px) {
	.toolbar_navigation-items .mainNavLink {
		padding: 0.5rem 1rem;
	}
}

.toolbar_navigation-items a {
	color: black;
	text-decoration: none;
	transition: all 0.4s ease-out;
}

.toolbar_navigation-items a:hover,
.toolbar_navigation-items a:active {
	color: #e11f2e;
	transition: all 0.3s ease-in;
}

@media (max-width: 1014px) {
	.toolbar_navigation-items {
		display: none;
	}
}

@media (min-width: 1015px) {
	.toolbar_toggle-button {
		display: none;
	}

	.toolbar_logo {
		margin-left: 0;
	}
}

.sjukrathjalfun,
.umOkkur {
	position: relative;
}

.sjukrathjalfun ul,
.umOkkur ul {
	position: absolute;
	background: white;
	box-shadow: 0px 1.5px 2px gray;
	margin-top: 1px;
	width: 100%;
	display: flex;
	justify-content: space-around;
	align-items: center;
	flex-direction: column;
	list-style: none;
	border-radius: 0px;
	opacity: 0;
	pointer-events: none;
	transform: translateY(-3px);
	transition: all 1s ease;
}

.sjukrathjalfun ul {
	height: 150px;
}

.umOkkur ul {
	height: 150px;
}

.sjukrathjalfun li,
.umOkkur li {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.sjukrathjalfun .mainNavLink:hover + ul,
.umOkkur .mainNavLink:hover + ul {
	opacity: 1;
	pointer-events: all;
	transform: translateY(0px);
}

.sjukrathjalfun ul:hover,
.umOkkur ul:hover {
	opacity: 1;
	pointer-events: all;
	transform: translateY(0px);
}

.activeTab {
	color: #e11f2e !important;
}
