.App-logo {
	height: 40vmin;
	pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}
	to {
		transform: rotate(360deg);
	}
}

h1 {
	font-family: "Open Sans", "Roboto", sans-serif;
	font-weight: 600;
}

h2,
h3 {
	font-family: "Open Sans", "Roboto", sans-serif;
	font-weight: 500;
}

h4 {
	font-family: "Open Sans", "Roboto", sans-serif;
	font-weight: 600;
}

p,
a,
li,
button,
::placeholder,
.content-name {
	font-family: "Open Sans", "Roboto", sans-serif;
	font-weight: 400;
	line-height: 1.5em;
}

@media (max-width: 400px) {
	main {
		width: 110%;
	}
}

@media (max-width: 363px) {
	main {
		width: 115%;
	}
}

@media (max-width: 347px) {
	main {
		width: 120%;
	}
}

@media (max-width: 332px) {
	main {
		width: 125%;
	}
}

@media (max-width: 318px) {
	main {
		width: 130%;
	}
}

@media (max-width: 290px) {
	main {
		width: 135%;
	}
}

/* Main */
.Body {
	padding: 0rem 6%;
	padding-bottom: 6em;
}

.Title {
	margin-top: 4em;
	margin-bottom: 1em;
}

.TitleWButton {
	display: flex;
	column-gap: 2em;
	margin-top: 4em;
}

@media (max-width: 750px) {
	.TitleWButton {
		display: inherit;
	}
}

::selection {
	background-color: #e11f2e;
	color: white; /* Optional: If you want to change the text color when selected. */
}

/* For browser compatibility, you might also want to include the following: */
::-moz-selection {
	background-color: #e11f2e;
	color: white; /* Optional */
}
