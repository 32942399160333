.cookieContainter {
	position: fixed;
	bottom: 0;
	left: 0px;
	right: 0px;
	background: white;
	color: black;
	box-shadow: -0.9px -0.1px 12px gray;
	transition: all 400ms ease-in;
	height: 9em;
	justify-content: center;
	align-items: center;
}

.cookieContainter.active {
	bottom: -20%;
	transition: all 1s ease-out;
}

.cookieContainterText p {
	margin-block-end: 0.5em;
	text-align: center;
}

.cookieContainterActions {
	display: flex;
	justify-content: center;
}

.cookieContainterActions div {
	justify-content: center;
	align-items: center;
	width: 10em;
}

.seeMor:hover {
	color: #e11f2e;
}

.cookieButton {
	background-color: #e11f2e;
	border: 0;
	color: white;
	padding: 12px 48px;
	font-size: 18px;
	margin-bottom: 16px;
	border-radius: 0px;
	cursor: pointer;
}
