/* Einstaklingur */

.EinstaklingurBody {
	padding: 4rem 16rem 4rem 4rem;
}

.Einstaklingur {
	display: flex;
}

.EinstaklingurLeft {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: center;
}

.EinstaklingurImgSize {
	height: 40rem;
}

.EinstaklingurRight {
	flex: 1;
}

@media (min-width: 550px) {
	.EinstaklingurRightTop {
		display: flex;
	}
}

.EinstaklingurEmail,
.EinstaklingurStadsetning {
	display: flex;
	align-items: center;
	flex: 1;
}

.EinstaklingurLogoStadsetning,
.EinstaklingurLogoEmail {
	height: 1.5rem;
}

.EinstaklingurLogoStadsetning {
	padding: 0px 10px;
}

.EinstaklingurLogoEmail {
	padding: 0px 7px;
}

.EinstaklingurInfo {
	flex: 1;
}

.EinstaklingurInfo div {
	margin: 0em 0em;
}

.EinstaklingurAddress {
	margin: 0.65em 0em;
}

.EinstaklingurEmailAddress {
	cursor: pointer;
	display: inline;
	transition: color 0.3s;
	white-space: break-spaces;
}

.EinstaklingurEmailAddress:hover {
	color: #e11f2e;
}

.EinstaklingurInfo h3 {
	font-size: 1em;
}

@media (max-width: 549px) {
	.EinstaklingurEmail {
		margin-top: -1em;
	}
}

.EinstaklingurTitle {
	flex: 2;
}

.EinstaklingurTitle h1 {
	margin: 0.3em 0em;
}

.EinstaklingurTitle h3 {
	margin: 0.3em 0em;
}

.EinstaklingurAbout {
	text-align: justify;
	line-height: 1.5rem;
}

.EinstaklingurJobs {
	padding-top: 0.2rem;
	text-align: left;
	line-height: 1.5rem;
}

.EinstaklingurOther {
	padding-top: 0.5rem;
	text-align: left;
	line-height: 1.5rem;
}

.EinstaklingurOther ul,
.EinstaklingurJobs ul {
	margin: 0rem;
}

.EinstaklingurJobs h3,
.EinstaklingurOther h3 {
	margin: 0rem;
	padding-bottom: 0.2em;
}

@media (max-width: 1700px) {
	.EinstaklingurBody {
		padding: 4rem 10rem;
	}

	.EinstaklingurLeft {
		flex: 0.5;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0rem 1rem;
	}

	.EinstaklingurRight {
		padding: 0rem 1rem;
		flex: 1;
	}

	.EinstaklingurImgSize {
		height: 30rem;
	}
}

@media (max-width: 1300px) {
	.EinstaklingurBody {
		padding: 5rem 6rem;
	}

	.EinstaklingurLeft {
		flex: 0.5;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0rem 1rem;
	}

	.EinstaklingurRight {
		padding: 0rem 1rem;
		flex: 1;
	}

	.EinstaklingurImgSize {
		height: 25rem;
	}
}

@media (max-width: 1100px) {
	.EinstaklingurBody {
		padding: 5rem 0rem;
	}

	.EinstaklingurLeft {
		flex: 0.5;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0rem 1rem;
	}

	.EinstaklingurRight {
		padding: 0rem 1rem;
		flex: 1;
	}

	.EinstaklingurImgSize {
		height: 25rem;
	}
}

@media (max-width: 900px) {
	.EinstaklingurBody {
		padding: 3rem 1rem;
	}

	.Einstaklingur {
		padding-top: 1rem;
		display: inline;
	}

	.EinstaklingurLeft {
		flex: 0.5;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0rem 1rem;
	}

	.EinstaklingurRight {
		padding: 0rem 1rem;
		flex: 1;
	}

	.EinstaklingurImgSize {
		height: 25rem;
	}
}

/* Custom Bullet */
.EinstaklingurRight ul {
	list-style: none;
	padding-left: 3rem;
}

.EinstaklingurJobs li,
.EinstaklingurOther li {
	margin-left: -1em;
	text-align: left;
	padding-bottom: 0.2em;
	text-indent: -2em;
}
.EinstaklingurJobs li:before,
.EinstaklingurOther li:before {
	content: "";
	height: 1em;
	width: 1em;
	background-image: url("../../assets/Icons/AtlasBall.svg");
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 2em;
}

/*--------------*/
/* Frettir */

.FrettirImgSize {
	width: 100%;
}

.FrettirGrid {
	display: flex;
	flex-wrap: wrap;
}

.FrettirEach {
	width: 29%;
	background-color: white;
	padding: 2%;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
	color: black;
	transition: all 0.6s ease-out;
}

.FrettirEach:hover {
	transition: all 0.4s ease-in-out;
	flex-direction: column;
	align-items: center;
	cursor: pointer !important;
}

.FrettirEach:hover {
	color: #e11f2e;
	transition: all 0.4s ease-in-out;
}

@media (max-width: 900px) {
	.FrettirEach {
		width: 46%;
		margin-bottom: 2em;
		align-items: center;
		text-decoration: none;
		color: black;
		transition: all 0.6s ease-out;
	}
}

.FrettirEach h2 {
	text-align: center;
}

.FrettirEach p {
	margin-top: 0rem;
}

/*--------------*/
/* FrontPage */

.bannerImageSpace {
	position: relative; /* Required for the absolute positioning of child elements */
	overflow: hidden;
	min-height: 300px;
}

.bannerImgSize {
	width: 100%;
	min-height: 300px;
	display: block;
	object-fit: cover;
}

.overlayText {
	text-align: center;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 3em;
	font-weight: 700;
	text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.85);
	width: 100%;
}

.lazy-load-image-background {
	display: block !important;
}

.frontInfoSpace {
}

.frontInfo {
	display: flex;
}

.frontInfoLeft {
	width: 50%;
}

.frontInfoRight {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 50%;
	text-align: center;
	align-items: center;
	text-decoration: none;
}

.frontpageInfoImg {
	width: 100%;
	overflow: hidden;
}

.frontInfoText {
	margin: 0.5em 1em;
}

.frontInfoDescriptionAddition {
	display: flex;
	align-items: center;
	column-gap: 0.5em;
}

.frontInfoHafasambandContainer {
	display: flex;
	justify-content: center;
}

.frontInfoHafasambandContainer h4 {
	margin: 0em;
}

.frontInfoDescriptionAdditionHS {
	width: 15em;
	margin: 0em;
	margin-bottom: 0.5em;
}

.frontInfoHafasamband {
	text-decoration: none;
	color: black;
	transition: color 0.4s;
}

.frontInfoHafasamband:hover {
	color: #e11f2e;
}

.frontInfoRight .btn-div {
	margin-top: 0.5em;
	justify-content: center;
}

.frontInfoRight .btn-div .btn {
	width: 100%;
}

@media (max-width: 510px) {
	.frontInfoRight .btn-div .btn {
		width: 80%;
	}
}

.frettirFrontPageLink > h2 {
	margin: 1.5em 0em;
}

.frettirFrontPageLink {
	text-align: center;
	color: black;
	text-decoration: none;
	transition: color 0.4s;
}

.frettirFrontPageLink:hover {
	cursor: pointer !important;
	color: #e11f2e;
}

@media (max-width: 1300px) {
	.frontInfo {
		display: inline;
	}
	.frontInfoLeft {
		width: 100%;
	}

	.frontInfoRight {
		width: 100%;
	}

	.infoFlex {
		margin: 0px !important;
	}

	.frontInfoDescriptionAddition {
		display: flow;
		padding-top: 0.5em;
	}
	.frontInfoDescriptionAddition h4,
	.frontInfoDescriptionAddition p {
		margin: 0em;
	}
}

@media (max-width: 550px) {
	/*
	.frontInfoDescItem {
		width: 24em;
	}
*/
	.frontInfoHafasambandContainer {
		display: inline-grid;
		justify-content: center;
	}

	.overlayText {
		font-size: 2em;
		width: 90%;
	}
}

.updatesSpace {
	margin: 3em;
	padding-bottom: 5em;
}

.infoIcon {
	margin-bottom: 0.5em;
	height: 3.5rem;
}

.infoTextTitle {
	display: flex;
	flex-direction: column;
}

.infoTextTitle h3 {
	margin: 0em;
}

.infoTextFlex {
	width: 20em;
}

@media (max-width: 1200px) {
	.infoTextFlex {
		padding: 1rem 0rem;
	}
}

.infoTextDescription {
	margin-top: 0.5em;
}

.info_navigation-items {
	display: flex;
	justify-content: center;
}

.infoSpace {
	margin: 2.5em 0em;
}

.info_navigation-items > ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-evenly;
	padding-inline-start: 0px;
}

.info_navigation-items > ul > a {
	width: 20%;
	justify-content: center;
}

@media (max-width: 1100px) {
	.info_navigation-items > ul > a {
		width: 20%;
	}
}

@media (max-width: 1000px) {
	.info_navigation-items > ul > a {
		width: 40%;
	}
}

@media (max-width: 600px) {
	.info_navigation-items > ul > a {
		width: 80%;
	}
}

.infoFlex {
	position: relative;
	margin: 0 1.5em;
	display: flex;
	text-decoration: none;
	color: black;
	text-align: center;
}

@media (min-width: 600px) {
	.infoFlex:hover {
		color: #e11f2e;
	}

	.infoFlex::before {
		content: "";
		display: block;
		position: absolute;
		top: 100%;
		height: 0.4em;
		width: 100%;
		background-color: #e11f2e;
		-webkit-transform: scale(0, 1);
		transform: scale(0, 1);
		transition: color 0.1s, -webkit-transform 0.2s ease-out;
		transition: color 0.1s, transform 0.2s ease-out;
		transition: color 0.1s, transform 0.2s ease-out,
			-webkit-transform 0.2s ease-out;
	}

	.infoFlex:active::before {
		background-color: #e11f2e;
	}

	.infoFlex:hover::before,
	.infoFlex:focus::before {
		-webkit-transform-origin: center top;
		transform-origin: center top;
		-webkit-transform: scale(1, 1);
		transform: scale(1, 1);
	}
}
/*--------------*/
/* Gjaldskra */

.gjaldskraBody {
	padding: 0rem 6%;
	padding-bottom: 6em;
}

.gjaldskraToggle {
	text-align: center;
	padding: 1em 0em;
}

.gjaldskraToggle div {
	display: inline-block;
}

.gjaldskraToggle input[type="radio"] {
	display: none;
}

.gjaldskraToggle input[type="radio"] + label {
	background-color: #e11f2e;
	color: #ffffff;
	padding: 0.5em 1.5em;
	cursor: pointer;
	margin: 0 10px;
	border: 2px solid transparent;
	text-shadow: 0 0 0 transparent;
	transition: background-color 0.3s, border-color 0.3s;
}

.gjaldskraToggle input[type="radio"]:hover + label {
	border-color: black;
	background-color: #e11f2e;
}

.gjaldskraToggle input[type="radio"]:checked + label {
	border-color: black;
	background-color: #e11f2e;
}

.GjaldskraFlex {
	display: flex;
	column-gap: 4em;
}

.GjaldskraSection {
	width: 50%;
	margin-left: 2em;
}

.GjaldskraSize {
	width: 100%;
}

.GjaldskraTextSection {
	width: 75%;
	margin-right: 2em;
	text-align: justify;
}

.Linkur {
	cursor: pointer;
	display: inline;
	transition: color 0.3s;
	white-space: break-spaces;
}

.Linkur:hover {
	color: #e11f2e;
}

@media (max-width: 1200px) {
	.GjaldskraBody {
		padding: 0rem 0%;
	}

	.GjaldskraFlex {
		flex-direction: column;
		align-items: center;
	}

	.GjaldskraSection {
		width: 100%;
		text-align: center;
		margin-left: 0em;
	}

	.GjaldskraTextSection {
		padding-top: 0em;
		width: 100%;
		padding-bottom: 3em;
	}
}

/*--------------*/
/* HafaSamband */

.SambandSections {
	display: flex;
}

.SambandLeftSection {
	width: 50%;
}

.SambandRightSection {
	width: 50%;
	line-height: 1.5rem;
	text-align: justify;
}

.HafaSambandTextSection div {
	display: flex;
	column-gap: 0.5em;
	margin-bottom: 0.5em;
}

.HafaSambandTextSection div h4,
.HafaSambandTextSection div p {
	margin: 0em;
}

.HafaSambandImage {
	width: 100%;
}

.HafaSambandLinkur {
	cursor: pointer;
	display: inline;
	transition: color 0.3s;
	white-space: break-spaces;
}

.HafaSambandLinkur:hover {
	color: #e11f2e;
}

.form {
	width: 40rem;
	position: relative;
	height: 60px;
	overflow: hidden;
	font-size: 24px;
	margin: 0.5em 0em;
}

.form input {
	width: 100%;
	height: 65%;
	color: black;
	padding-top: 20px;
	border: none;
	outline: none;
	font-size: 20px;
	background-color: #eeeeee;
}

.form label {
	position: absolute;
	bottom: -1px;
	left: 0%;
	width: 100%;
	height: 100%;
	pointer-events: none;
	border-bottom: 1px solid black;
}

.form label::after {
	content: "";
	position: absolute;
	left: 0px;
	bottom: -1px;
	height: 100%;
	width: 100%;
	border-bottom: 3px solid #e11f2e;
	transform: translateX(-100%);
	transition: transform 0.3s ease;
}

.content-name {
	position: absolute;
	bottom: 5px;
	left: 0px;
	transition: all 0.3s ease;
}

.form input:focus + .label-field .content-name,
.form input:valid + .label-field .content-name {
	transform: translateY(-30px);
	font-size: 14px;
	color: #e11f2e;
}

.form input:focus + .label-field::after,
.form input:valid + .label-field::after {
	transform: translateX(0%);
}

.btn {
	background-color: var(--background-color);
	color: white;
	padding: 1rem 2rem;
	border: none;
	outline: none;
	position: relative;
	cursor: pointer;
	font-size: 15px;
	text-decoration: none;

	--background-color: #e11f2e;
	--border-size: 2px;
}

.btn.btn-border-pop::before {
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	border: var(--border-size) solid black;
	transition: top, left, right, bottom, 150ms ease-in-out;
}

.btn.btn-border-pop:hover::before,
.btn.btn-border-pop:focus::before {
	top: calc(var(--border-size) * -1);
	left: calc(var(--border-size) * -1);
	right: calc(var(--border-size) * -1);
	bottom: calc(var(--border-size) * -1);
}

.btn-div {
	display: flex;
}

.btnLabel {
	margin: 0.2em;
}

.skilabod {
	height: 100rem;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

.form-large {
	width: 40rem;
	position: relative;
	height: 250px;
	overflow: hidden;
	font-size: 24px;
	margin: 0.5em 0em;
}

.form-large textarea {
	font: 14px Arial;
	width: 100%;
	height: 90%;
	color: black;
	padding-top: 20px;
	border: none;
	outline: none;
	font-size: 20px;
	background-color: #eeeeee;
	resize: none;
}

.form-large label {
	position: absolute;
	bottom: -1px;
	left: 0%;
	width: 100%;
	height: 100%;
	pointer-events: none;
	border-bottom: 1px solid black;
}

.form-large label::after {
	content: "";
	position: absolute;
	left: 0px;
	bottom: -1px;
	height: 100%;
	width: 100%;
	border-bottom: 3px solid #e11f2e;
	transform: translateX(-100%);
	transition: transform 0.3s ease;
}

.form-large textarea:focus + .label-field .content-name,
.form-large textarea:valid + .label-field .content-name {
	transform: translateY(-150%);
	font-size: 14px;
	color: #e11f2e;
}

.form-large textarea:focus + .label-field::after,
.form-large textarea:valid + .label-field::after {
	transform: translateX(0%);
}

#checkMarkPos {
	width: 30%;
	display: none;
}

.messageRecived {
	justify-content: center;
	display: flex;
	animation: fadeIn ease 3s;
	-webkit-animation: fadeIn ease 3s;
	-moz-animation: fadeIn ease 3s;
	-o-animation: fadeIn ease 3s;
	-ms-animation: fadeIn ease 3s;
}
@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@media (max-width: 1500px) {
	.SambandSections {
		display: block;
	}

	.SambandLeftSection {
		width: 100%;
	}

	.SambandRightSection {
		padding-top: 1em;
		width: 100%;
	}

	.form,
	.form-large {
		width: 100%;
	}
}

/*--------------*/
/* News */

.NewsTitle {
	padding: 2rem 0rem 0em 0rem;
}

.newsHeading {
	margin-block-end: 0px;
}

.newsDate {
	margin-block-start: 0px;
	margin-block-end: 0px;
}

.NewsContent {
	display: flex;
}

.NewsRight {
	width: 40%;
}

.NewsRight p {
	text-align: justify;
}

.NewsLeft {
	width: 60%;
	margin-left: 3em;
}

.NewsImgSize {
	width: 100%;
}

.OtherText {
	padding-top: 1em;
	display: flex;
}

@media (max-width: 950px) {
	.NewsContent {
		display: block;
	}

	.NewsRight {
		width: 100%;
	}

	.NewsLeft {
		width: 100%;
		margin-left: 0em;
	}
}

/*--------------*/
/* Page404 */

.Page404Logo {
	margin-top: 15em;
}

.Page404Logo,
.Page404 {
	display: flex;
	justify-content: center;
}

.Text404 {
	font-weight: bold;
	font-size: 25px;
}

/*--------------*/
/* Personuvernd */

.PersonuverndSections p {
	padding-bottom: 1rem;
	text-align: justify;
}

/*--------------*/
/* Sjukrathjalfun */

.TitleSjukra {
	width: 7em;
}

.btn-div-titles {
	display: flex;
	align-items: center;
	column-gap: 1em;
}

.btn-div-titles > a,
.btn-div-titles > button {
	padding: 0.5em 1.5em;
}

.btn {
	border: var(--border-size) solid transparent;
	transition: border-color 150ms ease-in-out;
}

.btn:hover {
	border: var(--border-size) solid black;
	transition: top, left, right, bottom, 150ms ease-in-out;
}

.btn.selected {
	border: 2px solid black;
}

.Sjukrathjalfun {
	display: flex;
}

.SjukrathjalfunText {
	flex: 2;
	margin-right: 5em;
}

.SjukrathjalfunText p {
	text-align: justify;
}

.SjukrathjalfunImage {
	flex: 1;
}

.SjukrathjalfunSize {
	height: 40em;
}

.SjukrathjalfunSections p {
	text-align: justify;
	line-height: 1.5rem;
	font-size: 15px;
}

.SjukrathjalfunLink {
}

@media (max-width: 1100px) {
	.SjukrathjalfunImage {
		display: none;
	}
	.SjukrathjalfunText {
		flex: 2;
		margin-right: 0em;
	}
}

/*--------------*/
/* Stadsetning*/

.stadSections {
	display: flex;
	text-align: justify;
	column-gap: 3em;
}

.stadSections h4 {
	margin-bottom: -1em;
}

.stadRightSection {
	text-align: justify;
	width: 50%;
	padding-bottom: 1em;
}

.stadLeftSection {
	width: 50%;
	margin-top: 1em;
	padding-bottom: 0.5em;
}

@media (max-width: 1100px) {
	.stadSections {
		display: inline;
	}
	.stadRightSection {
		width: 100%;
		padding-right: 0em;
	}

	.stadLeftSection {
		padding: 0em;
		padding-bottom: 1.5em;
		width: 100%;
		margin-left: 0em;
	}
}

@media (max-width: 768px) {
	.stadSections {
		height: 23rem;
	}
}

.map {
	height: 40vh;
	background-color: white;
	position: relative;
}

.stadImgSize {
	width: 100%;
}

.btn-div-stads {
	margin-top: 1.5em;
	display: flex;
	width: 25em;
}

/*--------------*/
/* Starfsfolk */

.titleButtons {
	justify-content: center;
	display: flex;
	column-gap: 1em;
	margin: 1em 0em;
}

.StarfsfolkImgSize {
	height: 25rem;
}

.StarfsfolkGrid {
	display: flex;
	flex-wrap: wrap;
	padding-bottom: 3em;
}

.StarfsfolkEach {
	width: 20%;
	background-color: white;
	padding: 2%;
	display: flex;
	flex-direction: column;
	align-items: center;
	text-decoration: none;
	color: black;
	transition: all 0.6s ease-out;
	line-height: 1.5em;
}

.StarfsfolkEach:hover {
	width: 20%;
	color: #e11f2e;
	transition: all 0.4s ease-in-out;
	padding: 2%;
	display: flex;
	flex-direction: column;
	align-items: center;
}

@media (max-width: 1300px) {
	.StarfsfolkEach {
		width: 25%;
		background-color: white;
		margin: 1rem 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-decoration: none;
		color: black;
		transition: all 0.6s ease-out;
	}

	.StarfsfolkEach:hover {
		width: 25%;
		color: #e11f2e;
		transition: all 0.4s ease-in-out;
		margin: 1rem 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media (max-width: 1000px) {
	.StarfsfolkEach {
		width: 40%;
		background-color: white;
		margin: 1rem 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-decoration: none;
		color: black;
		transition: all 0.6s ease-out;
	}

	.StarfsfolkEach:hover {
		width: 40%;
		color: #e11f2e;
		transition: all 0.4s ease-in-out;
		margin: 1rem 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

@media (max-width: 650px) {
	.StarfsfolkEach {
		width: 100%;
		background-color: white;
		margin: 1rem 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-decoration: none;
		color: black;
		transition: all 0.6s ease-out;
	}

	.StarfsfolkEach:hover {
		width: 100%;
		color: #e11f2e;
		transition: all 0.4s ease-in-out;
		margin: 1rem 1rem;
		display: flex;
		flex-direction: column;
		align-items: center;
	}
}

.StarfsfolkEach h2 {
	text-align: center;
	margin: 0.2em 0em;
	line-height: 1.3;
}

.StarfsfolkEach p {
	line-height: 1.5em;
	margin: 0em 0em;
}

/*--------------*/
/* UmOkkur */

.umOkkurImg {
	height: 10rem;
	padding: 0rem 0rem;
}

.umOkkurSections {
	display: flex;
	column-gap: 3em;
}

.umOkkurRightSection {
	width: 50%;
	text-align: justify;
}

.umOkkurRightSection p {
	margin-bottom: 3px;
}

.umOkkurRightSection ul {
	margin-top: 3px;
}

.umOkkurLeftSection {
	padding-top: 3em;
	width: 50%;
}
.umOkkurImages2 {
	display: none;
}

@media (max-width: 1014px) {
	.umOkkurSections {
		display: flex;
		height: 10%;
	}
	.umOkkurRightSection {
		width: 100%;
		line-height: 1.5rem;
		text-align: justify;
	}
	.umOkkurLeftSection {
		display: none;
	}
	.umOkkurImages2 {
		display: block;
		margin-top: -5em;
	}
}

.umOkkurInfo {
	margin-top: 1.5em;
}

.umOkkurInfo div {
	display: flex;
	column-gap: 0.5em;
	margin: 0.4em 0em;
}

.umOkkurInfo h4 {
	margin: 0em;
}

.umOkkurInfo p {
	margin: 0em;
}

/* Custom Bullet */
.umOkkurRightSection ul {
	list-style: none;
	padding-left: 0px;
}
.umOkkurRightSection li {
	text-align: left;
	padding-bottom: 0.2em;
}
.umOkkurRightSection li:before {
	content: "";
	height: 1em;
	width: 1em;
	background-image: url("../../assets/Icons/AtlasBall.svg");
	background-size: contain;
	background-repeat: no-repeat;
	padding-left: 2em;
}
