.footer {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: white;
	box-shadow: 0px 15px 25px gray;
}

.footer_navigation {
	display: flex;
	align-items: center;
	justify-content: center;
}

.footer_navigation-items ul {
	list-style: none;
	margin: 0;
	padding: 0;
	display: flex;
	column-gap: 3em;
}

/* When the screen width is 1024px or less */
@media (max-width: 1450px) {
	.footer_navigation-items ul {
		column-gap: 2em;
	}
}

/* When the screen width is 768px or less */
@media (max-width: 1350px) {
	.footer_navigation-items ul {
		column-gap: 1em;
	}
}

/* When the screen width is 480px or less */
@media (max-width: 1250px) {
	.footer_navigation-items ul {
		column-gap: 0.5em;
	}
}

@media (max-width: 1230px) {
	.emailFooter {
		display: none;
	}
}

.footer_navigation-items a {
	color: black;
	text-decoration: none;
	display: flex;
	align-items: center;
	height: 4.8rem;
}

.footer_navigation-items a:hover,
.footer_navigation-items a:active {
	color: #e11f2e;
}

.footer_navigation li img {
	padding: 4px 10px;
	height: 30px;
}

.footer_navigation li p {
	display: inline;
}

@media (max-width: 950px) {
	.footer {
		display: none;
	}
}
